import { NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { VerticalStepperService } from './vertical-stepper.service';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-vertical-stepper-component',
	template: `
		<mat-vertical-stepper class="flex-1" #stepper>
			<ng-template matStepperIcon="edit" let-index="index">
				<agdir-icon *ngIf="field.fieldGroup[index].props.icon" [icon]="field.fieldGroup[index].props.icon" />
			</ng-template>
			<ng-template matStepperIcon="number" let-index="index">
				<agdir-icon *ngIf="field.fieldGroup[index].props.icon" [icon]="field.fieldGroup[index].props.icon" />
			</ng-template>
			<ng-container *ngFor="let step of field.fieldGroup; let index = index; let last = last">
				<mat-step class="h-full" *ngIf="!step.hide">
					<ng-template matStepLabel>
						<div class="flex w-full justify-between">
							<span class="flex-1">{{ step.props.label }}</span>
							<!--						<agdir-icon [ngClass]="isValid(step) ? 'text-green-500' : 'text-gray-300'" />-->
						</div>
					</ng-template>
					<formly-field [field]="step"></formly-field>
				</mat-step>
			</ng-container>
		</mat-vertical-stepper>
	`,
	imports: [MatStepperModule, FormlyModule, NgForOf, NgIf, AgdirIconComponent],
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		`,
		`
			:host ::ng-deep .mat-step-label {
				width: 100%;
			}
		`,
		`
			:host ::ng-deep .mat-step-header .mat-step-icon {
				background-color: white !important;
				color: black !important;
			}

			:host ::ng-deep .mat-step-header .mat-step-icon .mat-icon {
				width: 24px !important;
				height: 24px !important;
			}
		`,
	],
})
export class VerticalStepperComponent extends FieldType<any> implements AfterViewInit, OnDestroy {
	verticalStepperService = inject(VerticalStepperService);
	busy$ = this.verticalStepperService.busy$;
	@ViewChild(MatStepper) matStepper!: MatStepper;

	ngOnDestroy() {
		this.verticalStepperService.deregisterStepperInstance();
	}

	ngAfterViewInit(): void {
		this.verticalStepperService.registerStepperInstance(this.matStepper);
	}

	isValid(field: FormlyFieldConfig): boolean {
		if (field.key) {
			return field.formControl?.valid || false;
		}

		return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
	}
}

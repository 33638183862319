import { SUPPORTED_LANGUAGE } from './index';

export const SUPPORTED_LANGUAGES_SHORT = new Map<SUPPORTED_LANGUAGE, string>([
	['no', 'NO'],
	['en', 'EN'],
	['es', 'ES'],
	['fi', 'SU'],
	['pl', 'PO'],
	['ua', 'УКР'],
	['it', 'IT'],
	['sv', 'SV'],
	['da', 'DA'],
]);

import { AgdirSelectOption } from '@agdir/agdir-forms';
import { Component, Input, output, signal } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-tab-toggle',
	template: `
		<div class="gap-0.5 flex p-1.5 items-center rounded-[10px] bg-blue-200">
			@for (tab of tabs; track $index) {
				<div
					class="cursor-pointer rounded-[5px] p-1 min-w-[100px] text-center text-semibold tracking-wide"
					[class.active]="activeTab() === tab"
					(click)="selectTab(tab)"
				>
					{{ tab.label }}
				</div>
			}
		</div>
	`,
	styles: [
		`
			.active {
				@apply bg-blue-50;
			}
		`,
		`
			:host {
				@apply flex items-center justify-center;
			}
		`,
	],
})
export class TabToggleComponent {
	@Input() tabs: AgdirSelectOption[] = [];
	@Input() activeTab = signal<AgdirSelectOption>(this.tabs[0]);
	tabChanged = output<AgdirSelectOption>();

	selectTab(tab: AgdirSelectOption) {
		this.activeTab.set(tab);
		this.tabChanged.emit(tab);
	}
}

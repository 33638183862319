import { Component, Input } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { LabelComponent } from './label.component';
import { I18nModule } from '@agdir/i18n/angular';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgForOf } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';

export enum WeatherConditions {
	clearsky = 'WEATHER:still',
	fair = 'WEATHER:light',
	rainshower = 'WEATHER:bit',
	rain = 'WEATHER:some',
	heavyrain = 'WEATHER:strong',
}

@Component({
	standalone: true,
	selector: 'agdir-weather-entry',
	template: `
		<agdir-label icon="cloud">
			{{ 'journal.weather.status' | transloco }}
		</agdir-label>

		<div class="grid gap-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 mb-3">
			<button
				*ngFor="let condition of conditions"
				type="button"
				class="rounded-md flex flex-col items-center justify-center"
				[class.selected]="condition === control.value"
				matRipple
				(click)="control.setValue(condition)"
			>
				<agdir-icon [icon]="'weather_icons:' + WeatherConditionIcons[condition]" class="w-10 h-10" />
				<span class="leading-0">{{ 'journal.weather.' + condition | transloco }}</span>
			</button>
		</div>
	`,
	styles: [
		`
			button {
				transition: all 200ms;
			}

			button:hover {
				background: rgba(0, 0, 0, 0.04);
			}

			button.selected {
				background: #405c70 !important;
				color: white;
			}
		`,
	],
	imports: [LabelComponent, I18nModule, MatButtonToggleModule, ReactiveFormsModule, NgForOf, MatRippleModule, AgdirIconComponent],
})
export class WeatherEntryComponent {
	WeatherConditionIcons: Record<WeatherConditions, string> = {
		[WeatherConditions.clearsky]: 'clearsky',
		[WeatherConditions.fair]: 'fair',
		[WeatherConditions.rainshower]: 'rainshower',
		[WeatherConditions.rain]: 'rain',
		[WeatherConditions.heavyrain]: 'heavyrain',
	};
	conditions: WeatherConditions[] = [
		WeatherConditions.clearsky,
		WeatherConditions.fair,
		WeatherConditions.rainshower,
		WeatherConditions.rain,
		WeatherConditions.heavyrain,
	];
	@Input() control: AbstractControl = new UntypedFormControl();
}

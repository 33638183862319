import { EntryPageItemModule } from '@agdir/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';
import { NgClass } from '@angular/common';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-expandable-panel',
	template: `
		<mat-expansion-panel #panel class="bg-blue-50 shadow-sm" hideToggle [ngClass]="props['className']">
			<mat-expansion-panel-header class="py-6 h-auto">
				<mat-panel-title class="flex justify-between m-0">
					<div class="flex gap-3 items-center flex-1">
						<agdir-icon *ngIf="props['icon']" [icon]="props['icon']" />
						<div *ngIf="props.label">{{ props.label }}</div>
					</div>
					<agdir-icon *ngIf="panel.expanded" icon="arrow_drop_up" />
					<agdir-icon *ngIf="!panel.expanded" icon="arrow_drop_down" />
				</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-container #fieldComponent></ng-container>
		</mat-expansion-panel>
	`,
	imports: [EntryPageItemModule, AgdirIconComponent, NgClass],
	styles: [
		`
			:host {
				display: flex;
				flex-direction: column;
			}
		`,
		`
			mat-list-item + div {
				margin-top: -1px;
			}
		`,
		`
			mat-list-item {
				height: auto !important;
				padding: 1em !important;
			}
		`,
		`
			mat-list-item ::ng-deep .mat-list-item-content {
				align-items: flex-start !important;
			}
		`,
	],
})
export class ExpandablePanelComponent extends FieldWrapper {}

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { LanguagesComponent } from '@agdir/agdir-language-selector';
import { HeaderComponent, PageComponent, VersionComponent } from '@agdir/layout';
import { CUSTOMER_PATHS } from '@agdir/services';
import { Component, inject, Signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { provideTranslocoScope, TranslocoPipe } from '@ngneat/transloco';
import * as version from '../../../../../../../version.json';
import { ResponsiveService } from '@agdir/core/angular';

@Component({
	standalone: true,
	selector: 'agdir-auth',
	template: `
		<agdir-header>
			<a href="{{ CUSTOMER_PATHS.ALL_FARMS }}" class="block">
				<img src="/assets/logo/logo-color.svg" alt="Logo" width="30" height="30" />
			</a>
			<agdir-languages displayAs="flags" [color]="isMobile() ? 'ghost' : 'outline'" [size]="isMobile() ? 'large' : 'small'" />
		</agdir-header>
		<agdir-page class="max-w-md">
			<router-outlet />
		</agdir-page>
		<footer class="flex flex-col gap-1 md:gap-2">
			<p class="mx-auto text-sm text-center prose flex flex-wrap justify-center gap-2 items-center flex-1">
				<agdir-icon icon="help" />
				<span [innerHTML]="'auth.contactUsPrompt' | transloco"></span>
			</p>
			<agdir-version [version]="version" />
		</footer>
	`,
	providers: [provideTranslocoScope({ scope: 'quick-auth', alias: 'auth' })],
	imports: [RouterOutlet, LanguagesComponent, TranslocoPipe, AgdirIconComponent, VersionComponent, HeaderComponent, PageComponent],
	styles: [
		`
			:host agdir-languages ::ng-deep agdir-button > button > span {
				@apply hidden lg:inline;
			}
		`,
	],
})
export class AuthPageComponent {
	isMobile: Signal<boolean> = inject(ResponsiveService).isMobile();
	protected readonly CUSTOMER_PATHS = CUSTOMER_PATHS;
	version = version;
}

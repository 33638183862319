import { AgdirIconComponent } from '@agdir/agdir-icons';
import { LanguagesComponent } from '@agdir/agdir-language-selector';
import { AgdirCardComponent } from '@agdir/core/angular';
import { I18nModule } from '@agdir/i18n/angular';
import { PageComponent, PageContentComponent, PageHeaderComponent } from '@agdir/layout';
import { ButtonComponent } from '@agdir/ui/button';
import { AsyncPipe, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { map } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { Step } from './domain';
import { newCompanySteps } from './new-company-steps';
import { NewCompanyService } from './new-company.service';

@Component({
	standalone: true,
	selector: 'agdir-new-company-page-steps',
	template: `
		<div class="flex items-center justify-center gap-3 ">
			@for (view of views; track $index; let i = $index) {
				<div
					class="bg-[#CEEFB9] w-8 h-8 rounded-full flex items-center justify-center"
					[class.opacity-50]="!isActive(view) && !view.done(newCompanyService.companyBuilder)"
					[routerLink]="view.path"
				>
					@if (view.done(newCompanyService.companyBuilder) && !isActive(view)) {
						<agdir-icon icon="check" />
					} @else {
						@if (isActive(view)) {
							<span class="font-bold">{{ i + 1 }}</span>
						} @else {
							{{ i + 1 }}
						}
					}
				</div>
			}
		</div>
	`,
	imports: [RouterModule, AgdirIconComponent],
})
export class NewCompanyPageStepsComponent {
	views: Step[] = newCompanySteps;
	readonly newCompanyService = inject(NewCompanyService);
	private readonly route = inject(ActivatedRoute);
	private readonly router = inject(Router);

	currentView = toSignal(
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			startWith(null),
			map((route) => this.getLastSegment()),
		),
	);

	isActive(view: Step) {
		return this.currentView() === view;
	}

	getLastSegment(): Step {
		let route = this.route;
		while (route.firstChild) {
			route = route.firstChild;
		}
		const currentPath = route.snapshot?.url.map((u) => u.path).join('/');
		return this.views.find((v) => v.path === currentPath) || this.views[0];
	}
}

@Component({
	standalone: true,
	selector: 'agdir-new-company-page',
	imports: [
		PageComponent,
		PageHeaderComponent,
		LanguagesComponent,
		PageContentComponent,
		RouterOutlet,
		I18nModule,
		RouterLinkActive,
		NzIconModule,
		AgdirCardComponent,
		RouterLink,
		ButtonComponent,
		NzProgressModule,
		AsyncPipe,
		DecimalPipe,
		AgdirIconComponent,
		NgOptimizedImage,
		NewCompanyPageStepsComponent,
	],
	providers: [NewCompanyService, provideTranslocoScope({ scope: 'newCompany', alias: 'newCompany' })],
	template: `
		<header>
			<a class="block">
				<img ngSrc="/assets/logo/logo-color.svg" alt="Logo" width="30" height="30" />
			</a>
			<agdir-languages></agdir-languages>
		</header>
		<div class="flex-1 flex flex-col justify-center items-center p-5 bg-green-50 sm:bg-transparent">
			<agdir-new-company-page-steps class="block sm:hidden mb-10" />

			<div class="step-container flex w-full sm:w-[1024px] sm:min-h-[640px] border border-gray-400 rounded-md">
				<aside class="bg-green-50 hidden sm:flex flex-col gap-7 p-14 w-1/4 rounded-l-md">
					@for (view of views; track $index; let i = $index) {
						<div routerLinkActive="font-bold" [routerLink]="view.path" class="flex items-center gap-2 cursor-pointer">
							@if (view.done(newCompanyService.companyBuilder)) {
								<agdir-icon icon="check" />
							} @else {
								<div class="w-3 h-3 rounded-full border border-gray-700"></div>
							}
							<div class="flex flex-row md:flex-col">
								<p [transloco]="view.title"></p>
							</div>
						</div>
					}
				</aside>
				@if (currentView | async; as current) {
					<main class="flex flex-1 flex-col bg-blue-50 p-6 rounded-md sm:p-14 sm:pl-24 sm:rounded-r-md">
						<div class="text-3xl font-bold" [transloco]="current.title"></div>
						<div class="prose prose-blue my-4 leading-5" [transloco]="current.description"></div>

						<router-outlet></router-outlet>

						<div class="flex flex-1 items-end mt-10">
							<div class="flex justify-between flex-1">
								@if (current.previous?.path) {
									<agdir-button
										size="small"
										color="ghost"
										[label]="'Back'"
										[routerLink]="current.previous?.path || ''"
										icon="arrow_back"
									/>
								}
								@if (newCompanyService.progress()) {
									<nz-progress [nzPercent]="newCompanyService.progress() | number: '0.0-0'"></nz-progress>
								}
								@if (!current.previous?.path) {
									<span class="Hello, fellow developer. justify needs an element, but on first step there is none"></span>
								}
								@if (current.next?.path) {
									<agdir-button size="small" color="green" [label]="'Next'" [routerLink]="current.next?.path || ''" icon="check" />
								}
								@if (!current.next?.path) {
									<agdir-button
										size="small"
										[isProcessing]="newCompanyService.isCreatingCompany()"
										(click)="creaateCompany()"
										color="green"
										label="newCompany.step5.action.next"
										icon="check"
									/>
								}
							</div>
						</div>
					</main>
				}
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				height: 100dvh;
				display: flex;
				flex-direction: column;
				flex: 1;
				align-items: center;

				.step-container {
					box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 0.08);
				}
			}
		`,
		`
			header,
			footer {
				@apply flex w-full justify-between py-3 px-6 md:py-5 md:px-10 items-center;
			}
		`,
		`
			header {
				@apply bg-white border-b border-gray-600;
			}
		`,
	],
})
export class NewCompanyPageComponent {
	private readonly route = inject(ActivatedRoute);
	readonly newCompanyService = inject(NewCompanyService);
	private readonly router = inject(Router);

	views: Step[] = newCompanySteps;

	currentView = inject(Router).events.pipe(
		filter((event) => event instanceof NavigationEnd),
		startWith(null),
		map((route) => this.getLastSegment()),
	);

	constructor() {
		for (let i = 0; i < this.views.length; i++) {
			this.views[i].previous = i - 1 >= 0 ? this.views[i - 1] : null;
			this.views[i].next = i + 1 < this.views.length ? this.views[i + 1] : null;
		}
	}

	getLastSegment(): Step {
		let route = this.route;
		while (route.firstChild) {
			route = route.firstChild;
		}
		const currentPath = route.snapshot.url.map((u) => u.path).join('/');
		return this.views.find((v) => v.path === currentPath) || this.views[0];
	}

	async creaateCompany() {
		const company = await this.newCompanyService.createCompany();
		this.newCompanyService.companyBuilder.clearLocalStorage();
		await this.router.navigate([`/${company._id}`]);
	}
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { I18nService } from '@agdir/i18n/angular';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { map, Observable, startWith, withLatestFrom } from 'rxjs';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-reason-entry',
	template: `
		<mat-form-field class="w-full">
			<mat-label [innerText]="props.label" *ngIf="props.label"></mat-label>
			<mat-chip-grid #chipList>
				<mat-chip-row (removed)="remove(i)" *ngFor="let reason of formControl?.value; index as i">
					{{ reason }}
					<button class="mb-1" matChipRemove>
						<agdir-icon icon="cancel" />
					</button>
				</mat-chip-row>
				<input
					#reasonInput
					[formControl]="listAutocompleteControl"
					[matAutocomplete]="auto"
					[matChipInputFor]="chipList"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[attr.placeholder]="props['placeholder'] ?? null"
				/>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedReasons($event)">
					<mat-option *ngFor="let reason of listOfReasonNr | async" [value]="reason">
						{{ reason }}
					</mat-option>
				</mat-autocomplete>
			</mat-chip-grid>
		</mat-form-field>
	`,
	imports: [MatFormFieldModule, MatChipsModule, ReactiveFormsModule, NgForOf, MatAutocompleteModule, AsyncPipe, NgIf, AgdirIconComponent],
})
export class ReasonEntryComponent extends FieldType<FieldTypeConfig> {
	@ViewChild('reasonInput') reasonInput!: ElementRef<HTMLInputElement>;

	separatorKeysCodes: number[] = [ENTER, COMMA];
	listAutocompleteControl = new UntypedFormControl();
	allReasons: Observable<string[]> = inject(I18nService)
		.selectTranslateObject('entryPage.reasonList')
		.pipe(map((translations) => Object.values(translations)));

	listOfReasonNr: Observable<string[]> = this.listAutocompleteControl.valueChanges.pipe(
		startWith(''),
		withLatestFrom(this.allReasons),
		map(([inputValue, theReasons]) => {
			const filteredValues = theReasons.filter((chosenReasons) => !(this.formControl?.value || '').includes(chosenReasons));
			return filteredValues.filter((v) => v.includes(inputValue));
		}),
	);

	remove(i: number) {
		this.formControl?.patchValue([...(this.formControl?.value || [])].filter((reason, d) => d !== i));
		this.formControl?.markAsDirty();
	}

	selectedReasons($event: MatAutocompleteSelectedEvent) {
		this.formControl?.setValue([...(this.formControl.value || []), $event.option.viewValue]);
		this.formControl?.markAsDirty();
		this.reasonInput.nativeElement.value = '';
		this.listAutocompleteControl.setValue('');
	}
}

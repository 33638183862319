import { Company, Profile } from '@agdir/domain';
import { AuthService, CompanyService, SharesService } from '@agdir/services';
import { Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { InvitationService } from '../invitation.service';
import { CompanyBuilder } from './company-builder';

@Injectable({ providedIn: 'root' })
export class NewCompanyService {
	companyBuilder = new CompanyBuilder();
	isCreatingCompany = signal(false);
	progress = signal(0);

	constructor(
		private auth: AuthService,
		private companyService: CompanyService,
		private sharesService: SharesService,
		private invitationService: InvitationService,
	) {}

	async createCompany(): Promise<Company> {
		const totalSteps = this.companyBuilder.invitations().length + 2; // company + my share
		this.updateProgress(1, totalSteps);
		this.isCreatingCompany.set(true);
		const company = await this.updateCompany();
		await this.auth.refreshToken();
		await this.linkInvitationsToCompany(company, totalSteps);
		await firstValueFrom(this.sharesService.getMyCompanies(true));
		this.isCreatingCompany.set(false);
		return company;
	}

	hasUnfinishedBusiness(): boolean {
		return this.companyBuilder.hasUnfinishedBusiness();
	}

	updateProgress(currentIndex: number, maxIndex: number) {
		this.progress.set((currentIndex / maxIndex) * 100);
	}

	removeInvitation(field: Profile) {
		this.companyBuilder.invitations.update((invitations) => invitations.filter((inv) => inv.invitee !== field));
		this.companyBuilder.storeInLocalStorage();
	}

	findByNumber(company: Company): Promise<Company> {
		return this.companyService.findByNumber(company);
	}

	private makeCompany() {
		return this.companyService.createCompanyForMe(this.companyBuilder.company());
	}

	private async updateCompany() {
		return await this.companyService.updateCompany(this.companyBuilder.company());
	}

	private async linkInvitationsToCompany(company: Company, totalSteps: number) {
		const mappedInvitations = this.companyBuilder.invitations().map((invitation) => ({ ...invitation, company }));
		for (const invitation of mappedInvitations) {
			const index = this.companyBuilder.invitations().indexOf(invitation);
			await this.invitationService.sendInviteToNumber(invitation);
			this.updateProgress(index + 3, totalSteps);
		}
	}
}

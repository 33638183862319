import { I18nModule, I18nService } from '@agdir/i18n/angular';
import { CompanyService } from '@agdir/services';
import { ButtonComponent } from '@agdir/ui/button';
import { Component, EventEmitter, inject, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { provideTranslocoScope } from '@ngneat/transloco';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { Invitation, InvitationI18n } from '../../domain/invitation';
import { InvitationService } from '../invitation.service';
import { InviteCustomerComponent } from './invite-customer.component';

@Component({
	standalone: true,
	selector: 'agdir-invite-customer-button',
	template: `
		<agdir-button color="primary" (click)="openModal()" icon="person_add" label="invitations.inviteOthers" />
	`,
	imports: [MatButtonModule, I18nModule, ButtonComponent, NzModalModule],
	providers: [provideTranslocoScope({ scope: 'invitations', alias: 'invitations' })],
})
export class InviteCustomerButtonComponent {
	@Output() newCode = new EventEmitter<Invitation>();
	private companyService = inject(CompanyService);
	private dialog = inject(NzModalService);
	private invitationService = inject(InvitationService);
	private i18nService = inject(I18nService);
	isSending = signal<boolean>(false);

	async openModal() {
		const i18n = await firstValueFrom(this.i18nService.selectTranslateObject<InvitationI18n>('invitations'));
		this.dialog.create({
			nzContent: InviteCustomerComponent,
			nzTitle: i18n.inviteOthersModalTitle,
			nzFooter: [
				{
					type: 'primary',
					size: 'large',
					label: this.i18nService.translate('general.ok'),
					loading: () => this.isSending(),
					nzIconType: 'mail',
					disabled: (invitationComponent?: InviteCustomerComponent) => {
						return invitationComponent?.userForm?.invalid!;
					},
					onClick: async (invitationComponent: InviteCustomerComponent) => {
						const i = await this.invitationService.sendInviteToNumber({
							...invitationComponent.invitation(),
							company: await firstValueFrom(this.companyService.getCurrentCompany()),
						});
						this.newCode.next(i);
						this.dialog.closeAll();
					},
				},
			],
		});
	}
}

import { AgdirCardComponent } from '@agdir/core/angular';
import { I18nModule, I18nService } from '@agdir/i18n/angular';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { ButtonComponent } from '../../../../../../ui/button/button.component';
import { AccessLevelToStringPipe } from '../../access-level-to-string.pipe';
import { InvitationWithProgress } from '../domain';
import { NewCompanyService } from '../new-company.service';

@Component({
	standalone: true,
	selector: 'agdir-new-company-step-5',
	providers: [provideTranslocoScope('newCompany')],
	template: `
		<div class="mt-5">
			<div class="flex items-center gap-2 mb-5">
				<h3 class="text-2xl font-bold" transloco="newCompany.step2.title"></h3>
				<agdir-button class="underline" color="ghost" (click)="editCompany()">Edit</agdir-button>
			</div>
			<dl>
				<dt transloco="newCompany.form.organizationName.label"></dt>
				<dd [innerText]="company.organizationName"></dd>
				<dt transloco="newCompany.form.businessBranch.label"></dt>
				<dd [innerText]="company.businessBranch"></dd>
				<dt transloco="newCompany.form.organizationNr.label"></dt>
				<dd [innerText]="company.organizationNr"></dd>
				<dt transloco="newCompany.form.organizationVat.label"></dt>
				<dd [innerText]="company.organizationVat"></dd>
				<dt transloco="newCompany.form.streetAddress.label"></dt>
				<dd [innerText]="company.address?.streetAddress"></dd>
				<dt transloco="newCompany.form.municipality.label"></dt>
				<dd [innerText]="company.address?.municipality"></dd>
				<dt transloco="newCompany.form.city.label"></dt>
				<dd [innerText]="company.address?.city"></dd>
				<dt transloco="newCompany.form.postal.label"></dt>
				<dd [innerText]="company.address?.postal"></dd>
				<dt transloco="newCompany.form.country.label"></dt>
				<dd [innerText]="company.address?.country"></dd>
			</dl>
		</div>
		<div class="mb-5">
			<div class="flex items-center gap-2 mb-5">
				<h3 class="text-2xl font-bold" transloco="newCompany.step4.title"></h3>
				<agdir-button class="underline" color="ghost" (click)="editInvitations()">Edit</agdir-button>
			</div>

			@if (newCompanyService.companyBuilder.phoneInvitations().length) {
				<div class="flex flex-col gap-2 mb-5">
					<h2 class="text-md font-bold" transloco="newCompany.step4.table.phoneInvitations"></h2>
					@for (invitation of newCompanyService.companyBuilder.phoneInvitations(); track 'invitee.phoneNumber') {
						<span [innerText]="invitation.invitee.phoneNumber || ''"></span>
					}
				</div>
			}

			@if (newCompanyService.companyBuilder.emailInvitations().length) {
				<div class="flex flex-col gap-2">
					<h2 class="text-md font-bold" transloco="newCompany.step4.table.emailInvitations"></h2>
					@for (invitation of newCompanyService.companyBuilder.emailInvitations(); track 'invitee.email') {
						<span [innerText]="invitation.invitee.email || ''"></span>
					}
				</div>
			}
			@if (!newCompanyService.companyBuilder.invitations().length) {
				<span class="text-blue-50">No invitations</span>
			}
		</div>
	`,
	styles: [
		`
			:host {
				@apply flex-1 flex flex-col gap-5;
			}
		`,
		`
			dd {
				@apply mb-5;
			}

			dt {
				@apply font-bold pr-3 mb-2 mr-3 border-r md:border-0;
			}
		`,
	],
	imports: [AgdirCardComponent, I18nModule, AccessLevelToStringPipe, ButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCompanyStep5Component {
	i18nService = inject(I18nService);
	protected readonly newCompanyService = inject(NewCompanyService);
	private readonly router = inject(Router);

	company = this.newCompanyService.companyBuilder.company();

	makeDecision(invitation: InvitationWithProgress): string {
		return invitation.phoneNumberValidated
			? this.i18nService.translate('newCompany.step4.table.decisionInviteExisting')
			: invitation.forceCreateInvitee
				? this.i18nService.translate('newCompany.step4.table.decisionCreateNew')
				: this.i18nService.translate('newCompany.step4.table.decisionInviteNew');
	}

	editCompany() {
		this.router.navigate(['new', 'step1']);
	}

	editInvitations() {
		this.router.navigate(['new', 'step3']);
	}
}

import { FormlyStuff } from '@agdir/agdir-forms';
import { AgdirCardComponent, BaseComponent } from '@agdir/core/angular';
import { LocationAddress, SUOPPORTED_COUNTRIES } from '@agdir/domain';
import { I18nModule } from '@agdir/i18n/angular';
import { DividerWithTextComponent } from '@agdir/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideTranslocoScope } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { map } from 'rxjs/operators';
import { CompanyAddressFindMeComponent } from '../../company-address-find-me.component';
import { AutocompleteComponent } from '../../location-lookup.component';
import { NewCompanyService } from '../new-company.service';

@Component({
	standalone: true,
	selector: 'agdir-company-builder-address',
	providers: [provideTranslocoScope('newCompany')],
	template: `
		<form nz-form nzLayout="vertical">
			<!--			<agdir-location-lookup-->
			<!--				(locationSelected)="formly.form.patchValue({ address: $event.address })"-->
			<!--				placeholder="general.searchCompany"-->
			<!--			></agdir-location-lookup>-->
			<!--			<agdir-divider-with-text class="my-2" text="general.orText"></agdir-divider-with-text>-->
			<!-- <agdir-company-address-find-me-component (companyAddress)="applyCompanyAddress($event, formly)"></agdir-company-address-find-me-component> -->
			<!-- <agdir-divider-with-text class="my-2" text="general.orText"></agdir-divider-with-text> -->
			<formly-form
				[fields]="formly.fields"
				[form]="formly.form"
				[model]="formly.model"
				(modelChange)="companyBuilder.addCompanyAddress($event)"
			></formly-form>
		</form>
	`,
	imports: [
		I18nModule,
		FormlyModule,
		AsyncPipe,
		FormsModule,
		NgIf,
		ReactiveFormsModule,
		AgdirCardComponent,
		AutocompleteComponent,
		DividerWithTextComponent,
		CompanyAddressFindMeComponent,
		AutocompleteComponent,
		CompanyAddressFindMeComponent,
		NzFormModule,
	],
})
export class CompanyBuilderAddressComponent extends BaseComponent implements AfterViewInit {
	newCompanyService = inject(NewCompanyService);
	companyBuilder = this.newCompanyService.companyBuilder;
	formly: FormlyStuff<LocationAddress> = this.makeFormly();
	@Output() statusChanged = this.formly.form.statusChanges.pipe(map((status) => status === 'VALID'));
	@Output() valueChanges = this.formly.form.statusChanges;
	@Output() modelUpdated = new EventEmitter<LocationAddress>();

	applyCompanyAddress(address: LocationAddress, formly: FormlyStuff<LocationAddress>) {
		formly.form.patchValue(address);
	}

	async ngAfterViewInit(): Promise<void> {
		if (!this.companyBuilder.company().address?.country) {
			this.companyBuilder.addCompanyAddress({ country: this.companyBuilder.company().countryOfOrigin });
		}
		this.formly.form.patchValue(this.companyBuilder.company().address || {});
	}

	makeFormly(): FormlyStuff<LocationAddress> {
		return {
			form: new FormGroup<any>({}),
			model: {
				// country: customerInfo.country || 'NO',
			} as LocationAddress,
			fields: this.makeFormlyFields(),
		};
	}

	private makeFormlyFields(): FormlyFieldConfig[] {
		return [
			{
				key: 'geoPoint',
			},
			{
				key: 'place',
			},
			{
				className: 'max-w-md mb-5',
				key: 'streetAddress',
				type: 'input',
				props: {
					translate: true,
					label: 'newCompany.form.streetAddress.label',
					description: 'newCompany.form.streetAddress.description',
				},
			},
			{
				className: 'max-w-md mb-5',
				key: 'municipality',
				type: 'input',
				props: {
					translate: true,
					label: 'newCompany.form.municipality.label',
					description: 'newCompany.form.municipality.description',
				},
			},
			{
				className: 'max-w-md mb-5',
				key: 'city',
				type: 'input',
				props: {
					translate: true,
					label: 'newCompany.form.city.label',
					description: 'newCompany.form.city.description',
				},
			},
			{
				className: 'max-w-md mb-5',
				key: 'postal',
				type: 'input',
				props: {
					translate: true,
					label: 'newCompany.form.postal.label',
					description: 'newCompany.form.postal.description',
				},
			},
			{
				className: 'max-w-md mb-5',
				key: 'country',
				type: 'select',
				props: {
					translate: true,
					label: 'newCompany.form.country.label',
					description: 'newCompany.form.country.description',
					attributes: { autocomplete: 'country' },
					required: true,
					valueProp: 'value',
					labelProp: 'label',
					options: this.makeCountriesOptions(),
				},
			},
		];
	}

	private makeCountriesOptions(): Array<{ label: string; value: string }> {
		return SUOPPORTED_COUNTRIES.map((br) => ({ label: br.name, value: br.countryCode }));
	}
}

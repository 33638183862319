import { AgdirSelectComponent, AgdirSelectOption } from '@agdir/agdir-forms';
import { BaseComponent } from '@agdir/core/angular';
import { LOCAL_STORAGE_USER_LANGUAGE_KEY, SUPPORTED_LANGUAGE, SUPPORTED_LANGUAGES } from '@agdir/i18n/angular';
import { AuthService, ProfileService } from '@agdir/services';
import { AgdirButtonColor, ButtonComponent, ButtonSizeType } from '@agdir/ui/button';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, Input, model, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { first, firstValueFrom, takeUntil } from 'rxjs';
import { SUPPORTED_LANGUAGES_SHORT } from '../../i18n/angular/src/SUPPORTED_LANGUAGES_SHORT';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';

interface Flag {
	lang: SUPPORTED_LANGUAGE;
	flag: string;
	name: string;
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-languages',
	template: `
		@if (displayAs === 'flags') {
			<agdir-button
				icon="g_translate"
				[label]="currentLanguageName()"
				[color]="color()"
				[size]="size()"
				nz-popover
				nzPopoverTitle="{{ 'general.languages.selectionLabel' | transloco }}"
				nzPopoverTrigger="hover"
				(click)="visible.set(!visible())"
				[nzPopoverContent]="contentTemplate"
				nzPopoverPlacement="bottom"
			/>
			<ng-template #contentTemplate>
				<ul class="flex flex-col gap-[15px] rounded-[6px]">
					@for (lang of availableFlags(); track $index) {
						<li class="hover:bg-blue-100" (click)="setActiveLang(lang.lang)">
							<button class="flex items-center text-sm text-purple-950 gap-[15px]">
								<img [ngSrc]="lang.flag" [attr.alt]="lang.name" width="24" height="18" />
								<span>{{ lang.name }}</span>
							</button>
						</li>
					}
				</ul>
			</ng-template>
		} @else if (displayAs === 'dropdown') {
			@for (lang of availableLangs; track $index) {
				<agdir-button [color]="control.value === lang[0] ? 'secondary' : 'ghost'" (click)="setActiveLang(lang[0])" [label]="lang[1]" />
			}
		} @else {
			<agdir-select class="hidden md:flex" [options]="availableLangsOption" [selectControl]="control" (changed)="setActiveLang($event.value)" />
			<agdir-select class="md:hidden" [options]="availableLangsOptionShort" [selectControl]="control" (changed)="setActiveLang($event.value)" />
		}
	`,
	imports: [AgdirSelectComponent, ButtonComponent, NgOptimizedImage, NzPopoverDirective, TranslocoPipe],
})
export class LanguagesComponent extends BaseComponent implements OnInit {
	currentLanguage = signal('');
	currentLanguageName = signal('');
	availableLangs: [SUPPORTED_LANGUAGE, string][] = [];
	availableFlags = signal<Flag[]>([]);
	@Input() control = new FormControl<SUPPORTED_LANGUAGE>('', { nonNullable: true });
	availableLangsOption: AgdirSelectOption[] = [];
	availableLangsOptionShort: AgdirSelectOption[] = []; // TODO: Make it nice
	@Input() displayAs: 'dropdown' | 'select' | 'flags' = 'select';
	transloco = inject(TranslocoService);
	color = input<AgdirButtonColor>('secondary');
	size = input<ButtonSizeType>('default');

	private readonly profileService = inject(ProfileService);
	private readonly authService = inject(AuthService);
	protected readonly visible = model(false);

	async setActiveLang(lang: string) {
		this.currentLanguage.set(lang);
		window.localStorage.setItem(LOCAL_STORAGE_USER_LANGUAGE_KEY, lang);
		const me = await firstValueFrom(this.authService.getCurrentCustomerSession());
		if (me._id) {
			await this.profileService.updatePreference('language', lang);
		}
		// this.transloco.setActiveLang(lang);
		// setDefaultOptions({ locale: DATEFNS_LOCALES.get(lang) });
		window.location.reload();
	}

	async ngOnInit(): Promise<void> {
		this.availableLangs = [...SUPPORTED_LANGUAGES.entries()];
		this.availableFlags.set(
			this.availableLangs.map(([lang, name]) => ({
				lang,
				flag: `/assets/images/flags/${lang.toLocaleUpperCase()}.svg`,
				name,
			})),
		);
		this.availableLangsOption = this.availableLangs.map(([value, label]) => ({ value, label }));
		this.availableLangsOptionShort = this.availableLangs.map(([value, label]) => ({
			value,
			label: SUPPORTED_LANGUAGES_SHORT.get(value) || label,
		}));
		this.transloco.langChanges$.pipe(first(), takeUntil(this.destroyed$)).subscribe((activeLang) => {
			this.control.setValue(activeLang);
			this.currentLanguage.set(activeLang);
			this.currentLanguageName.set(this.availableLangs.find(([lang]) => lang === activeLang)?.[1] || '');
		});
	}
}

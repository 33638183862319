import { BaseComponent } from '@agdir/core/angular';
import { I18nModule } from '@agdir/i18n/angular';
import { ButtonComponent } from '@agdir/ui/button';
import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, model, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { startOfDay, subDays } from 'date-fns';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { takeUntil } from 'rxjs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';

interface InternalDate {
	date?: Date | null | undefined;
}

interface InternalDateForm {
	date?: FormControl<Date | null | undefined>;
}

@Component({
	standalone: true,
	selector: 'agdir-datepicker',
	template: `
		<nz-form-item
			[class.flex-row]="horizontal === true || horizontal === 'true'"
			[class.items-center]="horizontal === true || horizontal === 'true'"
			[class.flex-col]="!horizontal || horizontal === 'false'"
			class="flex gap-2 justify-start"
		>
			<div class="flex items-center justify-between">
				<ng-content select="[beforeTitle]"></ng-content>
				@if (labelName && size == 'normal') {
					<nz-form-label class="flex-1 font-bold p-0">{{ labelName | transloco }}</nz-form-label>
				}
				@if (labelName && size == 'large') {
					<div class="flex-1 text-3xl md:text-4xl font-bold">{{ labelName | transloco }}</div>
				}
				@if (labelName && size == 'medium') {
					<div class="flex-1 text-xl font-light">{{ labelName | transloco }}</div>
				}
				<ng-content select="[fieldStatus]"></ng-content>
			</div>
			@if (description && size == 'large') {
				<div class="text-xl flex-1 font-light">{{ description | transloco }}</div>
			}
			<nz-form-control [nzExtra]="description && size !== 'large' ? (description | transloco) : ''">
				<div [formGroup]="internalForm" class="w-full flex-row flex justify-start gap-0.5">
					<nz-date-picker
						class="w-full inline-flex items-center rounded-[4px] border-normal border text-[15px] px-[20px] py-[8px] h-[38px] outline-none placeholder:text-blue-50 placeholder:font-normal text-theme-gray ant-picker"
						[nzShowTime]="showTimeSelection"
						[nzShowToday]="false"
						[nzFormat]="showTimeSelection ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
						[nzPlaceHolder]="(placeholder | transloco) || ''"
						[nzDisabled]="editDisabled"
						[nzRenderExtraFooter]="footer"
						formControlName="date"
					></nz-date-picker>
				</div>
			</nz-form-control>

			<ng-template #footer>
				<div *ngIf="showTomorrowTodayTomorrowShortcuts">
					<agdir-button (click)="setEntryDate($event, 1)" color="primary">
						{{ 'general.when.yesterday' | transloco }}
					</agdir-button>
					<agdir-button (click)="setEntryDate($event, 0)" color="primary">{{ 'general.when.today' | transloco }}</agdir-button>
					<agdir-button (click)="setEntryDate($event, -1)" color="primary">
						{{ 'general.when.tomorrow' | transloco }}
					</agdir-button>
				</div>
			</ng-template>
		</nz-form-item>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ReactiveFormsModule,
		NzDatePickerModule,
		ButtonComponent,
		MatFormFieldModule,
		I18nModule,
		NgForOf,
		MatInputModule,
		MatButtonModule,
		NgIf,
		MatDatepickerModule,
		NzTimePickerModule,
		NzFormModule,
		NzGridModule,
	],
})
export class DatepickerComponent extends BaseComponent implements OnChanges {
	internalForm = new FormGroup<InternalDateForm>({
		date: new FormControl<Date | null>(null, { nonNullable: false }),
	});
	dateStringModel = model<string | null>();
	dateDateModel = model<Date | null>();

	@Input() date = new FormControl<string | Date | null>(null); // ISO date
	@Input() horizontal: 'true' | 'false' | boolean = true;
	@Input() size: 'normal' | 'large' | 'medium' = 'normal';
	@Input() controlType: 'date' | 'string' = 'string';
	@Input() showTimeSelection = false;
	@Input() showTomorrowTodayTomorrowShortcuts = true;
	@Input() labelName = '';
	@Input() description = '';
	@Input() placeholder = '';
	@Input() editDisabled = false;
	@Input() maxDate?: Date;
	@Input() appearance: MatFormFieldAppearance = 'outline';

	@Output() dateChanged = new EventEmitter<Date | string | null>();

	constructor() {
		super();
		this.internalForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((values) => {
			this.setExternalControlDate(values);
		});
		effect(() => {
			const stringDate = this.dateStringModel();
			const dateDate = this.dateDateModel();
			if (stringDate) {
				this.isoToInternalControls(new Date(stringDate), false);
			}
			if (dateDate) {
				this.isoToInternalControls(dateDate, false);
			}
		});
	}

	setEntryDate(event: Event, number: number): void {
		event.preventDefault();
		this.isoToInternalControls(subDays(new Date(), number));
	}

	unset() {
		this.internalForm.patchValue({ date: null });
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['date']) {
			const value = changes['date'].currentValue?.value;
			if (value) {
				this.isoToInternalControls(new Date(value), false);
			}
		}
	}

	private isoToInternalControls(newDate: Date, emitEvent = true) {
		this.internalForm.patchValue({ date: newDate }, { emitEvent });
	}

	private setExternalControlDate({ date }: InternalDate) {
		if (date) {
			const dt = !this.showTimeSelection ? startOfDay(date) : date;
			const newDate = this.controlType === 'date' ? dt : dt.toISOString();
			this.date.setValue(newDate);
			this.dateChanged.emit(newDate);
			this.dateDateModel.set(dt);
			this.dateStringModel.set(dt.toISOString());
		} else {
			this.date.setValue(null);
			this.dateChanged.emit(null);
			this.dateDateModel.set(null);
			this.dateStringModel.set(null);
		}
		this.date.markAsDirty();
	}
}

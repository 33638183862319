import { IsAnonymousGuard, IsAuthenticated, SplashScreenGuard } from '@agdir/services';
import { Routes } from '@angular/router';
import { AuthPageComponent } from './pages/auth-page.component';
import { provideTranslocoScope } from '@ngneat/transloco';

export const AUTH_ROUTES: Routes = [
	{
		path: '',
		component: AuthPageComponent,
		canActivate: [SplashScreenGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				loadComponent: () => import('./pages/default-page.component').then((m) => m.DefaultPageComponent),
			},
			{
				path: 'sign-in',
				canActivate: [IsAnonymousGuard],
				loadComponent: () => import('./pages/anonymous-view.component').then((m) => m.AnonymousViewComponent),
				providers: [provideTranslocoScope({ scope: 'quick-auth', alias: 'auth' })],
			},
			{
				path: 'new-customer-profile',
				canActivate: [IsAuthenticated],
				loadComponent: () => import('./pages/new-customer-profile-page.component').then((m) => m.NewCustomerProfilePageComponent),
				providers: [provideTranslocoScope({ scope: 'quick-auth', alias: 'auth' })],
			},
			{
				path: 'welcome',
				canActivate: [IsAuthenticated],
				providers: [provideTranslocoScope({ scope: 'quick-auth', alias: 'auth' })],
				loadComponent: () => import('./pages/welcome-page.component').then((m) => m.WelcomePageComponent),
			},
			{
				path: 'received-invitations',
				canActivate: [IsAuthenticated],
				loadComponent: () => import('./views/invitations-view.component').then((m) => m.InvitationsViewComponent),
			},
			{
				path: 'join',
				loadComponent: () => import('./pages/join-page.component').then((m) => m.JoinPageComponent),
			},
			{
				path: 'join/:code',
				loadComponent: () => import('./pages/join-page.component').then((m) => m.JoinPageComponent),
			},

			{
				path: 'accept/:code',
				loadComponent: () => import('./pages/invitation/accept-invitation.component').then((m) => m.AcceptInvitationPageComponent),
			},
			{
				path: 'sign-out',
				loadComponent: () => import('./pages/sign-out-page.component').then((m) => m.SignOutPageComponent),
			},
			{
				path: 'token',
				canActivate: [IsAuthenticated],
				loadComponent: () => import('./pages/token-page.component').then((m) => m.TokenPageComponent),
			},
		],
	},
];

import { AgdirSelectComponent, PhoneNumberComponent } from '@agdir/agdir-forms';
import { CompanyAccessLevel } from '@agdir/domain';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, computed, effect, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { Invitation } from '../../domain/invitation';
import { AccessLevelToStringPipe } from './../access-level-to-string.pipe';
import { AgdirIconComponent } from '@agdir/agdir-icons';

interface UserForm {
	permission: FormControl<CompanyAccessLevel>;
	phoneNumber: FormControl<string>;
	name: FormControl<string>;
	surname: FormControl<string>;
	email: FormControl<string>;
	sendSms: FormControl<boolean>;
	sendEmail: FormControl<boolean>;
}

@Component({
	standalone: true,
	selector: 'agdir-invite-customer',
	template: `
		<form [formGroup]="userForm" nz-form nzLayout="vertical">
			<nz-form-item>
				<agdir-select [options]="ownershipOptions" [selectControl]="$any(userForm.get('permission')!)" [horizontal]="false" />
			</nz-form-item>

			<div class="flex flex-col md:flex-row md:gap-5 w-full">
				<nz-form-item class="md:basis-1/2 w-full">
					<nz-form-label>{{ 'profilePage.form.name' | transloco }}</nz-form-label>
					<nz-form-control>
						<input nz-input formControlName="name" placeholder="Name" class="ant-input" autocomplete="given-name" />
					</nz-form-control>
				</nz-form-item>

				<nz-form-item class="md:basis-1/2 w-full">
					<nz-form-label>{{ 'profilePage.form.surname' | transloco }}</nz-form-label>
					<nz-form-control>
						<input nz-input formControlName="surname" placeholder="Surname" class="ant-input" autocomplete="family-name" />
					</nz-form-control>
				</nz-form-item>
			</div>
			<div class="flex flex-col md:flex-row md:gap-5 w-full">
				<div class="md:basis-1/2 w-full">
					<nz-form-item>
						<nz-form-label>
							{{ 'profilePage.form.phoneNumber' | transloco }}
							@if (userForm.get('phoneNumber')!.validator) {
								<span class="ml-1 text-red-500">*</span>
							}
						</nz-form-label>
						<nz-form-control>
							<agdir-phone-number [control]="userForm.get('phoneNumber')!" />
						</nz-form-control>
					</nz-form-item>
					<nz-form-item>
						<label nz-checkbox formControlName="sendSms">{{ 'profilePage.form.sendSms' | transloco }}</label>
					</nz-form-item>
				</div>
				<div class="md:basis-1/2 w-full">
					<nz-form-item>
						<nz-form-label>
							{{ 'profilePage.form.email' | transloco }}
							@if (userForm.get('email')!.errors?.['required']) {
								<span class="ml-1 text-red-500">*</span>
							}
							@if (userForm.get('email')!.errors?.['email']) {
								<span class="ml-1 text-orange-500">*</span>
							}
						</nz-form-label>
						<nz-form-control>
							<input nz-input formControlName="email" placeholder="Email" class="ant-input" autocomplete="email" />
						</nz-form-control>
					</nz-form-item>
					<nz-form-item>
						<label nz-checkbox formControlName="sendEmail">
							{{ 'profilePage.form.sendEmail' | transloco }}
						</label>
					</nz-form-item>
				</div>
			</div>
			@if (showUSASMSPrompt()) {
				<p class="text-sm">
					<agdir-icon icon="warning" class="text-orange-500 float-left mr-1 mb-1" />
					Hello, farmers! We're upgrading to
					<strong>A2P 10DLC</strong>
					for better messages. During this, let's connect via email. This step ensures we're aligned with
					<strong>legal standards</strong>
					and keeps us connected.
				</p>
			}
		</form>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
		`
			:host ::ng-deep [type='tel'].invalid {
				@apply border-red-500;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		ReactiveFormsModule,
		NzFormModule,
		NzCheckboxModule,
		TranslocoPipe,
		AgdirSelectComponent,
		PhoneNumberComponent,

		ButtonComponent,
		AgdirIconComponent,
	],
})
export class InviteCustomerComponent {
	private accessLevelToStringPipe = inject(AccessLevelToStringPipe);
	userForm: FormGroup<UserForm> = new FormGroup<UserForm>({
		phoneNumber: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required, Validators.minLength(6)],
		}),
		permission: new FormControl(CompanyAccessLevel.CRUD, { nonNullable: true, validators: [Validators.required] }),
		email: new FormControl('', { nonNullable: true, validators: [Validators.email, Validators.required] }),
		name: new FormControl('', { nonNullable: true }),
		surname: new FormControl('', { nonNullable: true }),
		sendSms: new FormControl(false, { nonNullable: true }),
		sendEmail: new FormControl(true, { nonNullable: true }),
	});
	ownershipOptions = [
		{
			value: CompanyAccessLevel.OWNER,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.OWNER),
		},
		{
			value: CompanyAccessLevel.ADVISOR,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.ADVISOR),
		},
		{
			value: CompanyAccessLevel.CRUD,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.CRUD),
		},
		// {value:CompanyAccessLevel.Read, label:this.accessLevelToStringPipe.transform(CompanyAccessLevel.Read)},
	];
	formValueChangesSignal = toSignal(this.userForm.valueChanges);
	phoneValueChangesSignal = toSignal(this.userForm.get('phoneNumber')!.valueChanges);
	sendEmailChangesSignal = toSignal(this.userForm.get('sendEmail')!.valueChanges);
	showUSASMSPrompt = computed(() => this.phoneValueChangesSignal()?.startsWith('+1'));

	invitation: Signal<Partial<Invitation>> = computed(() => {
		const { phoneNumber, name, surname, email, sendSms, sendEmail, permission } = this.userForm.value;
		return {
			invitee: { phoneNumber, name, surname, email },
			sendSms,
			sendEmail,
			permission,
		} as Partial<Invitation>;
	});

	constructor() {
		effect(() => {
			const emailControl = this.userForm.get('email')!;
			const sendEmail = this.sendEmailChangesSignal();
			if (sendEmail) {
				emailControl.addValidators([Validators.required, Validators.email]);
			} else if (!this.showUSASMSPrompt()) {
				emailControl.removeValidators(Validators.required);
			} else if (this.showUSASMSPrompt()) {
				this.userForm.get('sendEmail')!.setValue(true);
			}
		});
		effect(() => {
			const sendSmsControl = this.userForm.get('sendSms')!;
			const sendEmailControl = this.userForm.get('sendEmail')!;
			if (this.phoneValueChangesSignal()?.startsWith('+1')) {
				sendSmsControl.setValue(false);
				sendSmsControl.disable();
				sendEmailControl.setValue(true);
			} else {
				sendSmsControl.enable();
			}
		});
	}
}
